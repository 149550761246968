import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller';
import slick from './modules/slick';
import homeBanner from './modules/homeBanner';
import projectFilters from './modules/projectFilters';
import pageNotFound from './modules/pageNotFound';

mobileNav();
loader();
scroller();
slick();
homeBanner();
projectFilters();
pageNotFound();