import $ from 'jquery';

export default function () {

	$(".c-inner-banner__filter li").click(function(){
	
		$('.c-inner-banner__filter li').removeClass('active');
		$(this).addClass('active');
		console.info($(this).attr('data-target'));

		$('.js-project-filter').hide();
		$('.' + $(this).attr('data-target')).show();
	})

}