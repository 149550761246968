import $ from 'jquery';

export default function() {

	$('.js-circle').on('mouseenter', function() {

		var target = $(this).attr('data-target');

		var element = $(`div[data-element="${target}"]`);

		element.addClass('active');

	});

	$('.js-circle').on('mouseleave', function() {

		var elements = $(`div[data-element]`);

		elements.removeClass('active');
		

	});

}