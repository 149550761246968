import $ from 'jquery';
import Slick from 'slick-carousel';

export default function () {

	$('.js-history-slider').slick({
		asNavFor: '.js-history-slider-menu',
		infinite: true,
		arrows: true,

	});

	$('.js-history-slider-menu').slick({
		asNavFor: '.js-history-slider',
		slidesToShow: 7,
		focusOnSelect: true,
		arrows: false,
		centerMode: true,
	});

	$('.js-projects-slider').slick({
		prevArrow: '.js-projects-slider-prev',
		nextArrow: '.js-projects-slider-next',
		slidesToShow: 3
	});



	$('.js-single-slider').slick({
		asNavFor: '.js-single-slider-menu',
		infinite: true,
		slidesToShow: 1,
		prevArrow: 'button.prev',
		nextArrow: 'button.next',
		arrows: true,
	});

	$('.js-single-slider-menu').slick({
		asNavFor: '.js-single-slider',
		slidesToShow: 5,
		focusOnSelect: true,
		arrows: false,
		centerMode: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3
				}

			},
			{
				breakpoint: 599,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});

}